import React, { lazy } from "react";
import { Navigate } from "react-router-dom";


//Dashboard
const Dashboard = lazy(() => import("../pages/dashboard"));

//Authentication pages
const Login = lazy(()=> import("src/pages/Authentication/Login"));
const Logout = lazy(()=> import("src/pages/Authentication/Logout"));
const Register = lazy(()=> import("src/pages/Authentication/Register"));
const ForgetPassword = lazy(()=> import("src/pages/Authentication/ForgetPassword"));
const UserProfile = lazy(()=> import("src/pages/Authentication/user-profile"));
const Clients = lazy(()=> import("../pages/clients/index"));
const ClientsDetails = lazy(()=> import("../pages/clients/details"));
const Brands = lazy(()=> import("../pages/brands/index"));
const Balance = lazy(()=> import("../pages/clients/balance"));
const Cars = lazy(()=> import("../pages/cars/index"));
const CarsDetails = lazy(()=> import("../pages/cars/details"));
const Providers = lazy(()=> import("src/pages/supplier"));
const ProvidersDetails = lazy(()=> import("src/pages/supplier/details"));
const Employees = lazy(()=> import("src/pages/employee"));
const EmployeeDetails = lazy(()=> import("src/pages/employee/details"));
const Budgets = lazy(()=> import("src/pages/budget/index"));
const BudgetDetails = lazy(()=> import("src/pages/budget/details"));
const BudgetPreview = lazy(()=> import("src/pages/budget/preview"));
const Orders = lazy(()=> import("src/pages/order"));
const OrderDetails = lazy(()=> import("src/pages/order/details"));
const OrderPreview = lazy(()=> import("src/pages/order/preview"));
const Incomes = lazy(()=> import("src/pages/income/index"));
const IncomesPending = lazy(()=> import("src/pages/income/pending"));
const IncomesDetails = lazy(()=> import("src/pages/income/details"));
const Expenses = lazy(()=> import("src/pages/expenses"));
const ExpensesDetails  = lazy(()=> import("src/pages/expenses/details"));
const OrderResumen = lazy(()=> import("src/pages/order/resumen"));
const EmployeeBalance = lazy(()=> import("src/pages/employee/balance"));
const FundBox = lazy(()=> import("src/pages/fundbox/index"));
const FundBoxDetails = lazy(()=> import("src/pages/fundbox/details"));
const FundBoxBalance = lazy(() => import('src/pages/fundbox/balance'));
const ClientResumen = lazy(()=> import("src/pages/clients/resumen"));



interface RouteProps {
  path: string;
  component: any;
  element?:any;
  exact?: boolean;
  
}

const userRoutes: Array<RouteProps> = [
  //User Profile
  { path: "/profile",  component: <UserProfile /> },
  //dashboard
  { path: "/dashboard", component: <Dashboard /> },
  // this route should be at the end of all other routes
  { path: "/", exact: true, component: <Navigate to="/dashboard" /> },
  { path: "/clients", component: <Clients /> },
  { path: "/clients/details/:id", component: <ClientsDetails /> },
  { path: "/clients/balance/:id", component: <Balance /> },
  { path: "/brands", component: <Brands /> },
  { path: "/cars", component: <Cars /> },
  { path: "/cars/details/:id", component: <CarsDetails /> },
  { path: "/suppliers", component: <Providers /> },
  { path: "/suppliers/details/:id", component: <ProvidersDetails /> },
  { path: "/employees", component: <Employees /> },
  { path: "/employees/details/:id", component: <EmployeeDetails /> },
  { path: "/budgets", component: <Budgets /> },
  { path: "/budgets/details/:id", component: <BudgetDetails /> },
  { path: "/budgets/preview/:id", component: <BudgetPreview /> },
  { path: "/orders",   component: <Orders />  },
  { path: "/orders/details/:id", component: <OrderDetails /> },
  { path: "/orders/preview/:id",   component: <OrderPreview /> },
  { path: "/incomes", component: <Incomes /> },
  { path: "/incomes/:_status", component: <IncomesPending /> },
  { path: "/incomes/details/:id", component: <IncomesDetails /> },
  { path: "/expenses", component: <Expenses /> },
  { path: "/expenses/details/:id", component: <ExpensesDetails /> },
  { path : "/orders/resumen/:id", component: <OrderResumen /> },
  { path : "/employees/balance/:id", component: <EmployeeBalance /> },
  { path : "/fundbox", component: <FundBox /> },
  { path : "/fundbox/details/:id", component: <FundBoxDetails /> },
  { path : "/fundbox/balance/:id", component: <FundBoxBalance /> },
  { path : "/clients/resumen/:id", component: <ClientResumen /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/register", component: <Register /> },
  { path: "/recoverpw", component: <ForgetPassword /> },
];

export { userRoutes, authRoutes };
